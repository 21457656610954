import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {rootRouterConfig} from './app.routes';
import {AppComponent} from './app.component';
import {PresseComponent} from './presse/presse.component';
import {LegalComponent} from './legal/legal.component';
import * as $ from 'jquery';
import {HttpClientModule} from '@angular/common/http';

import {
  ZENDESK_CONFIG,
  ZendeskORMModule,
} from 'ng-zendesk-orm';

@NgModule({
  declarations: [
    AppComponent,
    PresseComponent,
    LegalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    ZendeskORMModule,
    RouterModule.forRoot(rootRouterConfig, {useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})
  ],
  providers: [{
    provide: ZENDESK_CONFIG,
    useValue: {
      simulateAPI: 'no',
      subdomain: 'railz',
      token: 'lbPItKpxTk7LPyIscSxCDnKNd0ruqhlD1UZCdPUt'
    }
  }],
  bootstrap: [AppComponent]
})

export class AppModule {
}
