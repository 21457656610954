import {Routes, RouterModule} from '@angular/router';
import {PresseComponent} from './presse/presse.component';
import {LegalComponent} from './legal/legal.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'presse',
    component: PresseComponent,
    loadChildren: './presse/presse.module#PresseModule'
  },
  {
    path: 'mentions',
    component: LegalComponent,
    loadChildren: './legal/legal.module#LegalModule'
  },
  {
    path: '**',
    redirectTo: ''
  }
];
