import {Component, OnInit, OnDestroy} from '@angular/core';
import {LandingFixService} from '../shared/services/landing-fix.service';

@Component({
  selector: 'app-blog',
  templateUrl: './presse.component.html',
  styleUrls: ['./presse.component.scss']
})
export class PresseComponent implements OnInit, OnDestroy {

  constructor(
    private fix: LandingFixService
  ) {
  }

  ngOnInit() {
    this.fix.addFixPages();
  }

  ngOnDestroy() {
    this.fix.removeFixPages();
  }

}
